
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class MyPrize extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public prizeList!: cms.ActivityWinningRecordDto[];
  @Prop({
    type: String,
    default: "还没有活动",
  })
  public title!: string;
  /*
   * 关闭
   */
  close(): void {
    this.$emit("close");
  }
  /*
   * 去查看或兑换
   */
  goView(item: cms.ActivityWinningRecordDto): void {
    this.$emit("goView", item);
  }

  timeFilter(time?: string): string {
    if (!time) {
      return "";
    }
    let date = new Date(time);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let month: number | string = "";
    let days: number | string = "";
    let hours: number | string = "";
    month = m < 10 ? "0" + m : m;
    let d = date.getDate();
    days = d < 10 ? "0" + d : d;
    let h = date.getHours();
    hours = h < 10 ? "0" + h : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let min = minute < 10 ? "0" + minute : minute;
    let s = second < 10 ? "0" + second : second;
    return y + "." + month + "." + days + " " + hours + ":" + min;
  }
}
