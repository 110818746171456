
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class HisroryNull extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "还没有活动",
  })
  public title!: string;
  @Prop({
    type: Number,
    default: 1,
  })
  public type!: number; //1 为列表 2 为弹窗
}
